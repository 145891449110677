import {
  Box,
  MenuItem,
  Select,
  Stack,
  Typography,
  Card,
  useTheme,
  capitalize,
  Slider,
  InputLabel,
  FormControl,
  Grid,
  Chip,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
  Badge,
  Switch,
} from '@mui/material'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  useClimateAnalyticsWeather,
  useClimateAnalyticsWeatherBreakdown,
  useClimateMitigations,
  usePropertyClimateAnalyticsHeatStress,
} from '../../../hooks/analytics'
import ConfigProvider from 'balkerne-core/config'
import { Line, Chart } from 'react-chartjs-2'
import { useParams } from 'react-router-dom'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  ChartData,
} from 'chart.js'
import { DataGrid } from '@mui/x-data-grid'
// import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import { Layer, Map, MapRef, Source } from 'react-map-gl'
import { FeatureCollection, Point } from '@turf/turf'
import { HazardProps } from '../types'
import { CardTitle } from '../common/Card'
import LoadingIcon from '../../LoadingIcon'
import { useLocations } from '../../../hooks/locations'
import mapboxgl from 'mapbox-gl'
import { GradientLegend } from '../common/Legend'
import { useClimateProjections } from '../../../hooks/analytics'
import { usePropertyPricingProjections } from '../../../hooks/analytics'
import { capitalise } from 'balkerne-fn'
import { HelpRounded } from '@mui/icons-material'
import Alert from '@mui/material/Alert'

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
)

const PropertyFlood: React.FC<HazardProps> = ({ selectedScenario, setScenarios }) => {
  const { id: propertyId } = useParams()
  const { data, isLoading } = usePropertyPricingProjections(propertyId)
  const { data: categories, getMitigation } = useClimateMitigations(propertyId)
  const [hideMitigation, setHideMitigation] = useState(false)

  const permanentfloodBarrier = useMemo(() => getMitigation('flood', 'FLD-001'), [categories])
  const temporaryfloodBarrier = useMemo(() => getMitigation('flood', 'FLD-002'), [categories])
  let mitigation: any = null
  if (permanentfloodBarrier && permanentfloodBarrier.added) {
    mitigation = permanentfloodBarrier
  } else if (temporaryfloodBarrier && temporaryfloodBarrier.added) {
    mitigation = temporaryfloodBarrier
  }

  const availableScenarios = useMemo(() => {
    if (data) {
      return Object.keys(data.scenarios).filter(scenario => scenario.includes('rcp'))
    }
    return []
  }, [data])

  useEffect(() => {
    setScenarios(availableScenarios)

    return () => {
      setScenarios([])
    }
  }, [availableScenarios])

  if (isLoading) {
    return <LoadingIcon />
  }

  return (
    <Stack direction="column" spacing={5}>
      {mitigation && (
        <Stack>
          <Alert severity="info">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography>
                Results are affected by <strong>{mitigation.label}</strong>
              </Typography>
              <Divider orientation="vertical" flexItem sx={{ ml: 1 }} />
              <Switch checked={!hideMitigation} size="small" onChange={() => setHideMitigation(!hideMitigation)} />
            </Stack>
          </Alert>
        </Stack>
      )}

      {/* Flood Pricing */}
      <Stack direction="column">
        <Typography variant="overline">
          <strong>Damage Costs</strong>
        </Typography>
        <Grid container>
          {/* Chart */}
          <Grid item xs={12}>
            <FloodPricing rcp={selectedScenario} hideMitigation={hideMitigation} />
          </Grid>
        </Grid>
      </Stack>

      {/* Flood Depth */}
      <Stack direction="column">
        <Typography variant="overline">
          <strong>Flood Depth</strong>
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <FloodDepth rcp={selectedScenario} />
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  )
}

const floodSources = ['river', 'surface', 'coastal', 'coastal_undefended'] as const
const scenarios = ['rcp26', 'rcp45', 'rcp60', 'rcp85'] as const

type FloodSource = (typeof floodSources)[number]

const FloodPricing = ({ rcp, hideMitigation }: { rcp: string; hideMitigation: boolean }) => {
  const scenario = rcp
  const { id: propertyId } = useParams()
  const { data, isLoading } = usePropertyPricingProjections(propertyId)
  const pricing = useMemo(() => data?.scenarios, [data])

  console.log(pricing)

  const insurance_amount = data?.property_insurance_amount ?? 0
  const currency_symbol = '£'
  const [floodSource, setFloodSource] = useState<FloodSource>('river')
  const [yearIndex, setYearIndex] = useState<number>(0)

  const [isHidingBuilding, setIsHidingBuilding] = useState<boolean>(false)
  const [isHidingContents, setIsHidingContents] = useState<boolean>(false)

  const { data: categories, getMitigation, isLoading: isMitigationsLoading } = useClimateMitigations(propertyId)

  const permanentfloodBarrier = useMemo(() => getMitigation('flood', 'FLD-001'), [categories])
  const temporaryfloodBarrier = useMemo(() => getMitigation('flood', 'FLD-002'), [categories])

  let valueScaling = 1.0
  let mitigation = null
  if (permanentfloodBarrier && permanentfloodBarrier.added) {
    valueScaling = 0.05
    mitigation = permanentfloodBarrier
  } else if (temporaryfloodBarrier && temporaryfloodBarrier.added) {
    valueScaling = 0.4
    mitigation = temporaryfloodBarrier
  }
  if (hideMitigation) {
    valueScaling = 1.0
  }

  const getPercentiles = (mean: number, sd: number) => {
    const p5 = mean - sd * 1.96
    const p95 = mean + sd * 1.96
    return { p5, p95 }
  }

  const getAnnualDamage = useCallback(
    (scenario: string, floodSource: FloodSource, yearIndex: number, damageType: 'building' | 'contents') => {
      if (pricing !== null) {
        const stats = pricing[scenario]?.['data']?.[yearIndex]?.[floodSource]?.[damageType]
        if (stats == null) return null
        const p = getPercentiles(stats.ad, stats.ad_sd)
        return {
          mean: Math.ceil(stats.ad * valueScaling),
          p5: Math.ceil(p.p5 * valueScaling),
          p95: Math.ceil(p.p95 * valueScaling),
        }
      }
      return null
    },
    [pricing, valueScaling],
  )

  const getAnnualDamageArray = useCallback(
    (scenario: string, floodSource: FloodSource, damageType: 'building' | 'contents') => {
      if (pricing !== null) {
        const retval: { mean: number; p5: number; p95: number }[] = []
        for (const period of pricing[scenario]['data']) {
          const stats = period[floodSource][damageType]
          if (stats == null) return []
          const p = getPercentiles(stats.ad, stats.ad_sd)
          retval.push({
            mean: Math.ceil(stats.ad * valueScaling),
            p5: Math.ceil(p.p5 * valueScaling),
            p95: Math.ceil(p.p95 * valueScaling),
          })
        }
        return retval
      }
      return []
    },
    [pricing, valueScaling],
  )

  if (!scenarios.includes(scenario as any)) {
    return (
      <Stack justifyContent="center" alignItems="center" height="500">
        <Typography variant="h5">No data available for this scenario</Typography>
      </Stack>
    )
  }

  console.log(pricing)

  return (
    <>
      {!isLoading ? (
        <>
          {pricing !== null && pricing !== undefined ? (
            <>
              {/* Source & Scenario Toggles */}
              <Stack gap={3} mb={4} direction="row">
                <Stack direction="row" alignItems="center" gap={1} sx={{ display: scenario ? 'flex' : 'none' }}>
                  <Typography variant="body1">Flood Source</Typography>
                  <ToggleButtonGroup
                    size="small"
                    color="primary"
                    value={floodSource}
                    exclusive
                    onChange={(e: any) => setFloodSource(e.target.value)}>
                    {floodSources?.map(value => (
                      <ToggleButton key={value} value={value}>
                        {capitalise(value.split('_').join(' '), true)}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </Stack>
              </Stack>

              {/* Cards */}
              <Grid container gap={3} wrap="nowrap">
                <Grid item xs={6} lg={4}>
                  <Card>
                    <Typography variant="subtitle1">Site Valuation</Typography>
                    <Typography variant="h4">
                      {currency_symbol}
                      {addSeparators(insurance_amount)}
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Card>
                    <Typography variant="subtitle1">Building Avg. Annualised Loss</Typography>
                    <Typography variant="h4">
                      {currency_symbol}
                      {addSeparators(getAnnualDamage(scenario, floodSource, yearIndex, 'building')?.mean ?? 0)}
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Card>
                    <Typography variant="subtitle1">Contents Avg. Annualised Loss</Typography>
                    <Typography variant="h4">
                      {currency_symbol}
                      {addSeparators(getAnnualDamage(scenario, floodSource, yearIndex, 'contents')?.mean ?? 0)}
                    </Typography>
                  </Card>
                </Grid>
              </Grid>

              {/* Slider */}
              <Box sx={{ mx: 2, py: 3 }}>
                {scenario && (
                  <Slider
                    color="primary"
                    defaultValue={0}
                    valueLabelFormat={value =>
                      `${pricing?.[scenario]?.['data'][value]['year_begin']}-${pricing?.[scenario]?.['data'][value]['year_end']}`
                    }
                    valueLabelDisplay="auto"
                    onChange={(e, value) => setYearIndex(value as number)}
                    marks
                    min={0}
                    // @ts-ignore
                    max={pricing?.[scenario]?.['data']?.length - 1 ?? 0}
                  />
                )}
              </Box>

              {/* Chart */}
              <Stack>
                <Line
                  data={{
                    labels: pricing?.[scenario]['data']?.map(row => `${row.year_begin}-${row.year_end}`) ?? [],
                    datasets: [
                      {
                        label: 'Building Damage | p95',
                        data: getAnnualDamageArray(scenario, floodSource, 'building').map(period => period.p95),
                        fill: false,
                        borderColor: '#2196f3',
                        tension: 0.1,
                        pointRadius: 0,
                        showLine: false,
                        hidden: isHidingBuilding,
                      },
                      {
                        label: 'Building Damage',
                        data: getAnnualDamageArray(scenario, floodSource, 'building').map(period => period.mean),
                        fill: 0,
                        borderColor: '#2196f3',
                        backgroundColor: '#2196f322',
                        tension: 0.1,
                        hidden: isHidingBuilding,
                      },
                      {
                        label: 'Building Damage | p5',
                        data: getAnnualDamageArray(scenario, floodSource, 'building').map(period => period.p5),
                        borderColor: '#2196f3',
                        backgroundColor: '#2196f322',
                        fill: 1,
                        tension: 0.1,
                        pointRadius: 0,
                        showLine: false,
                        hidden: isHidingBuilding,
                      },
                      {
                        label: 'Contents Damage | p95',
                        data: getAnnualDamageArray(scenario, floodSource, 'contents').map(period => period.p95),
                        borderColor: '#f5c84c',
                        backgroundColor: '#f5c84c22',
                        fill: false,
                        tension: 0.1,
                        pointRadius: 0,
                        showLine: false,
                        hidden: isHidingContents,
                      },
                      {
                        label: 'Contents Damage',
                        data: getAnnualDamageArray(scenario, floodSource, 'contents').map(period => period.mean),
                        fill: 3,
                        borderColor: '#f5c84c',
                        backgroundColor: '#f5c84c22',
                        tension: 0.1,
                        hidden: isHidingContents,
                      },
                      {
                        label: 'Contents Damage | p5',
                        data: getAnnualDamageArray(scenario, floodSource, 'contents').map(period => period.p5),
                        borderColor: '#f5c84c',
                        backgroundColor: '#f5c84c22',
                        fill: 4,
                        tension: 0.1,
                        pointRadius: 0,
                        showLine: false,
                        hidden: isHidingContents,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        labels: {
                          filter: function (item, chart) {
                            return !item.text.includes(' | ')
                          },
                        },
                        onClick: (event, legendItem, legend) => {
                          switch (legendItem.text) {
                            case 'Building Damage':
                              setIsHidingBuilding(!isHidingBuilding)
                              break
                            case 'Contents Damage':
                              setIsHidingContents(!isHidingContents)
                              break
                            default:
                              break
                          }
                        },
                      },
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                      y: {
                        min: 0,
                        ticks: {
                          callback: function (value, index, values) {
                            return value + currency_symbol
                          },
                        },
                        beginAtZero: false,
                      },
                    },
                  }}
                />
              </Stack>
            </>
          ) : (
            <Stack justifyContent="center" alignItems="center" height="500">
              <Typography variant="h5">No data available for this property</Typography>
            </Stack>
          )}
        </>
      ) : (
        <Stack justifyContent="center" alignItems="center" height="500">
          <CircularProgress />
        </Stack>
      )}
    </>
  )
}

function addSeparators(num: number, separator: string = ',', gap: number = 3): string {
  const numStr = num.toString()
  const reversedStr = numStr.split('').reverse().join('')
  let formattedStr = ''
  for (let i = 0; i < reversedStr.length; i++) {
    formattedStr += reversedStr[i]
    if ((i + 1) % gap === 0 && i !== 0 && i !== reversedStr.length - 1) {
      formattedStr += separator
    }
  }
  return formattedStr.split('').reverse().join('')
}

const FloodDepth = ({ rcp }: { rcp: string }) => {
  const scenario = rcp
  const { id: propertyId } = useParams()
  const { data: projections, isLoading } = useClimateProjections(propertyId)
  const [type, setType] = useState('')
  const [chance, setChance] = useState<number | undefined>()
  const [display, setDisplay] = useState('chart')
  const { data: categories } = useClimateMitigations(propertyId)

  // const barrier = categories?.find(cat => cat.name === 'flood')?.find(mit => mit.reference === 'FLD-001')
  const barrier = useMemo(() => {
    if (categories) {
      const category = categories?.find(cat => cat.name === 'flood')
      if (category) {
        return category.mitigations?.find(mit => mit.reference === 'FLD-001')
      }
    }
    return null
  }, [categories])

  const scenarioOptions = Object.keys(projections ?? [])
  const typeOptions = Object.keys(projections?.[scenario] ?? [])
  const chanceOptions: any[] = useMemo(() => {
    const chances = new Set()
    if (projections?.[scenario]?.[type]) {
      for (const row of projections[scenario][type]) {
        if (!chances.has(row['one_in_n_prob'])) {
          chances.add(row['one_in_n_prob'])
        }
      }
    }
    return Array.from(chances)
  }, [projections, scenario, type])

  useEffect(() => {
    if (scenarioOptions.length > 0) {
      const scenario = scenarioOptions[0]
      const type = Object.keys(projections[scenario])[0]
      const chance = projections[scenario][type][0]['one_in_n_prob']
      setType(type)
      setChance(chance)
    }
  }, [projections])

  useEffect(() => {
    setChance(chanceOptions[0])
  }, [type])

  const columns = useMemo(
    () => [
      {
        field: 'year_start',
        headerName: 'Year',
        flex: 1,
        minWidth: 150,
        renderCell: params => `${params.row.year_start} - ${params.row.year_end}`,
      },
      {
        field: 'min',
        type: 'number',
        width: 150,
        headerName: 'Min. Depth',
        renderCell: params => `${params.row.min}m`,
      },
      {
        field: 'mean',
        headerName: 'Mean Depth',
        width: 150,
        type: 'number',
        renderCell: params => `${params.row.mean}m`,
      },
      {
        field: 'max',
        headerName: 'Max. Depth',
        width: 150,
        type: 'number',
        renderCell: params => `${params.row.max}m`,
      },
      {
        field: 'paa',
        headerName: '% of Area Affected',
        type: 'number',
        width: 150,
        renderCell: params => `${(params.row.paa * 100).toFixed(2)}%`,
      },
    ],
    [],
  )

  const rows = useMemo(() => {
    if (projections?.[scenario]?.[type]) {
      return projections?.[scenario]?.[type].filter(row => row.one_in_n_prob === chance)
    }
    return []
  }, [scenario, type, chance])

  const typeLabel = {
    FLRF_U: 'River',
    FLSW_U: 'Surface Water',
    STSU_U: 'Coastal',
    STSU_D: 'Coastal (Defended)',
  }

  const isChart = display === 'chart'
  const chartOpacity = '5A'

  const datasets = [
    {
      label: 'Min. Depth',
      data: rows.map(row => row.min),
      fill: 'origin',
      backgroundColor: '#e8eefc' + chartOpacity,
      borderColor: '#9cb5f5',
    },
    {
      label: 'Mean Depth',
      data: rows.map(row => row.mean),
      fill: 0,
      backgroundColor: '#d1dbf5' + chartOpacity,
      borderColor: '#9cb5f5',
    },
    {
      label: 'Max. Depth',
      data: rows.map(row => row.max),
      fill: 1,
      backgroundColor: '#9cb5f5' + chartOpacity,
      borderColor: '#9cb5f5',
    },
    {
      label: '% of Area Affected',
      data: rows.map(row => row.paa * 100),
      fill: 'origin',
      backgroundColor: '#FFFFFF' + '00',
      borderColor: '#EE4B2B',
      yAxisID: 'percentageYAxis',
    },
  ]

  if (barrier && barrier?.value != null && barrier?.added) {
    datasets.push({
      label: barrier.label,
      data: rows.map(row => barrier?.value),
      backgroundColor: '#FFFFFF' + '00',
      borderColor: '#FFD700',
    } as any)
  }

  return (
    <>
      {/* Toggle Buttons */}
      <Stack direction="row" gap={2} mb={2} sx={{ display: scenario ? 'flex' : 'none' }}>
        <Stack gap={0.5}>
          <Typography variant="body1">Display</Typography>
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={display}
            exclusive
            onChange={(e: any) => setDisplay(e.target.value)}>
            <ToggleButton value={'table'}>Table</ToggleButton>
            <ToggleButton value={'chart'}>Chart</ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Stack gap={0.5}>
          <Typography variant="body1">Flood Type</Typography>
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={type}
            exclusive
            onChange={(e: any) => setType(e.target.value)}>
            {typeOptions.map(value => (
              <ToggleButton key={value} value={value}>
                {typeLabel[value]}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Stack>
        <Stack gap={0.5}>
          <Typography variant="body1">Probability (years)</Typography>
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={chance}
            exclusive
            onChange={(e: any) => setChance(Number.parseInt(e.target.value))}>
            {chanceOptions?.map((value: any) => (
              <ToggleButton key={value} value={value}>
                {`1-in-${value}`}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Stack>
      </Stack>
      {isChart ? (
        <Box>
          <Line
            height={200}
            data={{
              labels: rows.map(row => `${row.year_start}-${row.year_end}`),
              datasets: datasets,
            }}
            options={{
              scales: {
                y: {
                  ticks: {
                    callback: function (value, index, values) {
                      return value + 'm'
                    },
                  },
                  beginAtZero: true,
                  suggestedMax: 1.0,
                },
                percentageYAxis: {
                  position: 'right',
                  ticks: {
                    callback: function (value, index, values) {
                      return value + '%'
                    },
                  },
                  beginAtZero: true,
                  max: 100,
                  min: 0,
                },
              },
            }}
          />
        </Box>
      ) : (
        <Box height="700">
          <DataGrid
            rows={rows}
            columns={columns}
            loading={isLoading}
            components={{
              NoRowsOverlay: () => (
                <Stack height={'100%'} alignItems="center" justifyContent="center">
                  <Typography variant="body1">No data available for this property</Typography>
                </Stack>
              ),
            }}
            sx={{ height: 700 }}
            density="compact"
            getRowId={row => 'river_sea' + row.year_start + row.one_in_n_prob}
          />
        </Box>
      )}
    </>
  )
}

export default PropertyFlood
